import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { getTrackinglist, cancelOrder, saveReturnImages, getOrderReasons, sendOrderInvoice, getReturnableShipmentItems, postReturnOrderRequest } from "../../helper/backend-methods";
import { cardIcons } from "../checkout/payment-cards";
import OrderProductDetails from "./order-product-details";
import { analyticsPageEvent } from "../../components/Analytics";
import "../../assets/css/--page-account-order-details.css"
import noImage from "../../assets/images/no-image.jpg"
import returnRequestImage from '../../assets/images/Return Items.jpg'
import UploadImagePlaceholder from "../../assets/images/upload-image-placeholder.png"
import T from "../../locale/translations-main";


function ViewOrderDetails(props) {
    let params = useParams();
    const orderID = params.id
    const [configurePageTitle, configureBreadcrumb] = props.methods
    const [orderDetails, setOrderDetails] = useState(null);
    const [shipping, setShipping] = useState(null);
    const [billing, setBilling] = useState(null);
    const [loading, setLoading] = useState(true)
    const [language, setLanguage] = useState("en")
    const [orderReasons, setOrderReasons] = useState([])
    const [cancelPopup, setCancelPopup] = useState(false)
    const [selectedReason, setSelectedReason] = useState(0)
    const [errors, setErrors] = useState({ reason: "" })
    const [apiLoading, setApiLoading] = useState(false)
    const [removeZetca, setRemoveZetca] = useState(false)
    const [update, setUpdate] = useState(0)
    // const [quantity, setQuantity] = useState(1);
    const [isOpen, setIsOpen] = useState(false);
    const [reasonReturnSection, setReasonReturnSection] = useState(true);
    const [selectedReasonId, setSelectedReasonId] = useState(0);
    const [proofPicture, setProofPicture] = useState(UploadImagePlaceholder);
    const [returnModalPictureUrl, setReturnModalPictureUrl] = useState(noImage);
    const [iscopied, setIsCopied] = useState(false);
    const [pictureErrors, setPictureErrors] = useState({ proofImage: "" })
    const [returnableItems, setReturnableItems] = useState([])
    const [isReturnable, setIsReturnable] = useState(false)
    const [returnableIndexes, setReturnableIndexes] = useState({ ShippingId: 0, ProductId: 0 })
    const [returnFormErrors, setReturnFormErrors] = useState([]);
    const [returnFormResponse, setReturnFormResponse] = useState([{ ResponseMessage: "", TrackingId: 0 }]);
    // const [returnFormErrorMessage, setReturnFormErrorMessage] = useState("");

    const dropdownRef = useRef(null);
    const breadcrumbItems = [
        { title: "Home", link: "/" },
        { title: "Orders", link: "/account/orders" },
        { title: "Order Details", link: "" },
    ]

    // This is the function for copy
    async function copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }
    const handleCopyClick = (copyText) => {
        // Asynchronously call copyTextToClipboard
        copyTextToClipboard(copyText)
            .then(() => {
                // If successful, update the isCopied state value
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 2000);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    

    const statuses = {
        "Delivered": {
            "en": "Delivered",
            "ar": "تم التوصيل"
        },
        "Out For Delivery": {
            "en": "Out For Delivery",
            "ar": "خارج للتوصيل"
        },
        "OutForDelivery": {
            "en": "Out For Delivery",
            "ar": "خارج للتوصيل"
        },
        "PartiallyOutForDelivery": {
            "en": "Partially Out For Delivery",
            "ar": "جزئيًا في طريقه للتسليم" 
        },
        "Processing": {
            "en": "Processing",
            "ar": "يعالج"
        },
        "Order placed": {
            "en": "Order placed",
            "ar": "تم الطلب"
        },
        "Pending": {
            "en": "Pending",
            "ar": "قيد الانتظار"
        },
        "Cancelled": {
            "en": "Cancelled",
            "ar": "ألغيت"
        },
        "PartiallyDelivered_Processing": {
            "en": "Partially Delivered Processing",
            "ar": "تسليم جزئي للمعالجة",
        },
        "PartiallyDelivered_Completed": {
            "en": "Partially Delivered",
            "ar": "سلمت جزئيا",
        },
        "Received": {
            "en": "Received",
            "ar": "تلقى"
        },
        "ProcessedForPicking": {
            "en": "Processed For Picking",
            "ar": "المصنعة للقطف"
        },
        "PartiallyProcessedForPicking": {
            "en": "Partially Processed For Picking",
            "ar": "تمت معالجته جزئيًا للاختيار"
        },
        "ProcessedForShipping": {
            "en": "Processed For Shipping",
            "ar": "معالجتها للشحن"
        },
        "PartiallyProcessedForShipping": {
            "en": "Partially Processed For Shipping",
            "ar": "تمت المعالجة جزئياً للشحن"
        },
        "HandedOverToCourier": {
            "en": "Handed Over To Courier",
            "ar": "تسليمه لمندوب الشحن"
        },
        "PartiallyHandedOverToCourier": {
            "en": "Partially Handed Over To Courier",
            "ar": "تم تسليمها جزئيًا إلى شركة الشحن"
        },
        "InTransit": {
            "en": "In Transit",
            "ar": "في مرحلة انتقالية"
        },
        "PartiallyInTransit": {
            "en": "Partially In Transit",
            "ar": "جزئيًا في النقل"
        },
        "Attempted": {
            "en": "Attempted",
            "ar": "حاول"
        },
        "PartiallyAttempted": {
            "en": "Partially Attempted",
            "ar": "محاولة جزئية"
        },
        "ReturnToOrigin": {
            "en": "Return To Origin",
            "ar": "العودة إلى الأصل"
        },
        "PartiallyReturnToOrigin": {
            "en": "Partially Return To Origin",
            "ar": "العودة جزئيًا إلى الأصل"
        },
        "PartialDeliveryInProgress": {
            "en": "Partial Delivery In Progress",
            "ar": "التسليم الجزئي قيد التنفيذ"
        },
        "PartiallyDelivered": {
            "en": "Partially Delivered",
            "ar": "سلمت جزئيا"
        },
        "CancelledByCustomer": {
            "en": "Cancelled By Customer",
            "ar": "ألغى العميل"
        }
    }
    const addressTitles = {
        "home": {
            "en": "Home",
            "ar": "بيت",
        },
        "partner": {
            "en": "Partner",
            "ar": "شريك",
        },
        "work": {
            "en": "Work",
            "ar": "عمل",
        }
    }

    useEffect(() => {
        if (props.consent.page) {
            analyticsPageEvent("Order Details");
        }
    }, [props.consent.page])

    useEffect(() => {
        const lang = localStorage.getItem("LANG")
        if (lang && lang.length) {
            setLanguage(lang)
        }
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        configurePageTitle("Order Details")
        configureBreadcrumb(breadcrumbItems)
    }, [])

    useEffect(() => {
        setLoading(true)
        getTrackinglist({ orderId: orderID }).then(({ data }) => {
            if (data.Status) {
                setOrderDetails(data.Data)
                setBilling(data.Data.BillingAddress)
                setShipping(data.Data.ShippingAddress)
                setLoading(false)
                // console.log("Order detail",data.Data)

                // getReasonTypes().then(({data})=>{
                //     console.log(data);
                // })

                let fetchReasons = false;
                let reasonType = "";
                if (data.Data.Status === "Received" || data.Data.Status === "Processing") {
                    fetchReasons = true;
                    reasonType = "UserCancelReason";
                }
                else if (data.Data.Status === "Delivered" || data.Data.Status === "PartialDeliveryInProgress" || data.Data.Status === "PartiallyDelivered") {
                    fetchReasons = true;
                    reasonType = "UserReturnReason";
                }
                if (fetchReasons) {
                    getOrderReasons("?type=" + reasonType).then(({ data }) => {
                        if (data.Status) {
                            setOrderReasons(data.Data)
                        }
                    }).catch((e) => {
                        console.log(e);
                    })
                }
            }
            else {
                setLoading(false)
            }
        }).catch((e) => {
            console.log(e);
        })
        const lang = localStorage.getItem("LANG")
        if (lang && lang.length) {
            setLanguage(lang)
        }
    }, [orderID, update]);


    const validateSubmit = () => {
        const errors = { reason: "" };
        let flag = false;
        if (!selectedReason) {
            errors.reason = <T>You must select a reason to cancel your order.</T>;
            flag = true
        }
        setErrors(errors);
        if (!flag) {
            setApiLoading(true)
            cancelOrder({ "OrderId": orderID, "ReasonId": selectedReason }).then(({ data }) => {
                if (data.Status) {
                    setUpdate(update + 1)
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: "smooth"
                    });
                }
                else {
                    const errors = { reason: "" };
                    errors.reason = <T>Your order could not be cancelled at the moment.</T>;
                    setErrors(errors);
                }
            }).catch((e) => {
                console.log(e)
                const errors = { reason: "" };
                errors.reason = <T>Your order could not be cancelled at the moment.</T>;
                setErrors(errors);
            })
        }
    }

    const sendZetcaInvoice = () => {
        setApiLoading(true)
        setIsOpen(false)
        sendOrderInvoice("?orderId=" + orderID).then(({ data }) => {
            if (data.Status) {
                setRemoveZetca(true);
            }
            setApiLoading(false)
            setTimeout(() => {
                setRemoveZetca(false);
            }, 5000);
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }).catch((e) => {
            setApiLoading(false)
            console.log(e)
        })
    }

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        setLoading(true)
        getReturnableShipmentItems({ 'orderId': orderID }).then(({ data }) => {
            if (data.Status) {
                if (data.Data.length) {
                    setIsReturnable(true)
                }
                let errorStates = []
                const processedData = data.Data.map((shipment) => {
                    let errorChild = []
                    const items = {
                        ShipmentId: shipment.ShipmentId,
                        ShipmentItems: shipment.ShipmentItems.map((product) => {
                            errorChild.push({ ImageError: "", ReasonError: "", QuantityError: "" })
                            return {
                                ...product,
                                ReasonId: 0,
                                ReasonText: "",
                                ProofImage: null,
                                BlobImage: UploadImagePlaceholder,
                                ProofImagePath: null,
                                returnQuantity: 1,
                                IsSelected: false
                            }
                        })
                    }
                    errorStates.push(errorChild);
                    return items;
                })
                setReturnFormErrors(errorStates)
                setReturnableItems(processedData)
            }
            setLoading(false)
        }).catch((e) => {
            console.log(e)
            setLoading(false)
        });
    }, [orderID]);

    const toggleDropdown = () => {
        setIsOpen((prevState) => !prevState);
    };
    const toggleReturnSections = (reasonReturnState) => {
        // setReasonReturnSection((prevShowSection1) => !prevShowSection1);
        setReasonReturnSection(reasonReturnState);
    };

    const updateProofPicture = (e, shipmentId, productId) => {
        const event = e;
        var allowedExtension = ['jpeg', 'jpg', 'png'];
        const errors = { proofImage: "" }
        if (event.target.files && event.target.files[0]) {
            if (!allowedExtension.includes(event.target.value.split('.').pop().toLowerCase())) {
                errors.proofImage = <T>Allowed image types are jpeg, jpg, png only.</T>;
            }
            else {
                //////// creating image path /////////////
                var imageURL = event.target.files[0];
                const objectURL = URL.createObjectURL(imageURL);
                /////////////////////////////////////
                var reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]);
                reader.onloadend = function (e) {
                    setProofPicture(e.target.result)
                    const updatedReturnableItems = returnableItems.map((shipment) => {
                        if (shipment.ShipmentId === shipmentId) {
                            return {
                                ...shipment,
                                ShipmentItems: shipment.ShipmentItems.map((p) => {
                                    if (p.ProductId === productId) {
                                        return {
                                            ...p,
                                            ProofImage: event.target.files[0],
                                            BlobImage: reader.result,
                                            ProofImagePath: objectURL
                                        }
                                    }
                                    else {
                                        return p;
                                    }
                                })
                            }
                        }
                        else {
                            return shipment
                        }
                    })
                    setReturnableItems(updatedReturnableItems)
                };
            }
        }
        setPictureErrors(errors);
    }

    const updateReturnableProductIsSelected = (e, shipmentId, productId) => {
        const updatedReturnableItems = returnableItems.map((shipment) => {
            if (shipment.ShipmentId === shipmentId) {
                return {
                    ...shipment,
                    ShipmentItems: shipment.ShipmentItems.map((p) => {
                        if (p.ProductId === productId) {
                            return {
                                ...p,
                                IsSelected: e.target.checked
                            }
                        }
                        else {
                            return p;
                        }
                    })
                }
            }
            else {
                return shipment
            }
        })
        setReturnableItems(updatedReturnableItems)
    }

    const updateReturnableProductQuantity = (returnQuantity, shipmentId, productId) => {
        const updatedReturnableItems = returnableItems.map((shipment) => {
            if (shipment.ShipmentId === shipmentId) {
                return {
                    ...shipment,
                    ShipmentItems: shipment.ShipmentItems.map((p) => {
                        if (p.ProductId === productId) {
                            return {
                                ...p,
                                returnQuantity: returnQuantity,
                            }
                        }
                        else {
                            return p;
                        }
                    })
                }
            }
            else {
                return shipment
            }
        })
        setReturnableItems(updatedReturnableItems)
    }

    const updateReturnableProductReason = (reasonId, reasonText, shipmentId, productId) => {
        setSelectedReasonId(reasonId)
        const updatedReturnableItems = returnableItems.map((shipment) => {
            if (shipment.ShipmentId === shipmentId) {
                return {
                    ...shipment,
                    ShipmentItems: shipment.ShipmentItems.map((p) => {
                        if (p.ProductId === productId) {
                            return {
                                ...p,
                                ReasonId: reasonId,
                                ReasonText: reasonText
                            }
                        }
                        else {
                            return p;
                        }
                    })
                }
            }
            else {
                return shipment
            }
        })
        setReturnableItems(updatedReturnableItems)
    }


    const submitReturnRequest = () => {
        const returnForm = new FormData();
        const images = returnableItems.filter((s)=> s.ShipmentItems.filter((si)=> si.IsSelected))
        console.log(images)
        returnForm.append("OrderId", orderID)
        let flag = false;
        let errorCollection = []
        let selectedFlag = false
        let index = 0;
        returnableItems.map((shipment) => {
            let childError = []
            shipment.ShipmentItems.map((p) => {
                let error = { ReasonError: "", QuantityError: "" }
                if (p.IsSelected) {
                    selectedFlag = true
                    if (p.returnQuantity == 0) {
                        error.QuantityError = <T>Please select valid quantity for the returns</T>
                        flag = true;
                    }
                    if (p.ReasonId == 0) {
                        error.ReasonError = <T>Please select valid reason for the returns</T>
                        flag = true;
                    }
                    if(p.ProofImage !== null){
                        returnForm.append("ReturnOrderItems[" + index + "][Image]", p.ProofImage)
                    }
                    returnForm.append("ReturnOrderItems[" + index + "][ShipmentId]", shipment.ShipmentId)
                    returnForm.append("ReturnOrderItems[" + index + "][OrderItemId]", p.OrderItemId)
                    returnForm.append("ReturnOrderItems[" + index + "][ReasonId]", p.ReasonId)
                    returnForm.append("ReturnOrderItems[" + index + "][Quantity]", p.returnQuantity)
                    index++;
                }
                childError.push(error);
            })
            errorCollection.push(childError)
        })
        setReturnFormErrors(errorCollection)
        if (!flag) {
            if (selectedFlag) {
                setLoading(true)
                postReturnOrderRequest(returnForm).then(({ data }) => {
                    if (data.Status) {
                        setReturnFormResponse({ ...returnFormResponse, TrackingId: data.Data.OrderNo })
                        if(data.Data.ReturnOrderItemIds && data.Data.ReturnOrderItemIds.length){
                            const returnIds = [...new Set(data.Data.ReturnOrderItemIds)];
                            const blobImagesArray = returnableItems.filter((s)=> s.ShipmentItems.filter((si)=> si.IsSelected).length).map((i)=> i.ShipmentItems ).flat().filter((i)=>i.IsSelected).map((i)=>{ return { q: i.Quantity, img: i.BlobImage } }).flatMap(({ q, img }) => Array.from({ length: q }, () => ({ q, img })));
                            const dataImages = returnIds.map((p,i)=>{ return { ReturnorderItemId: p, Base64String: blobImagesArray[i].img && blobImagesArray[i].img!==UploadImagePlaceholder ? blobImagesArray[i].img : "" } })
                            saveReturnImages(dataImages).then(({data})=>{
                                openReturnSuccessModal()
                                closeReturnModal()
                                setLoading(false)
                            }).catch((e)=>{
                                openReturnSuccessModal()
                                closeReturnModal()
                                setLoading(false)
                            })
                        }
                        else{
                            openReturnSuccessModal()
                            closeReturnModal()
                            setLoading(false)
                        }
                    }
                    else {
                        setReturnFormResponse({ ...returnFormResponse, ResponseMessage: <T>There is an error while submitting yor request. Please try again.</T> })
                        setTimeout(() => {
                            setReturnFormResponse({ ...returnFormResponse, ResponseMessage: "" })
                        }, 5000);
                        setLoading(false)
                    }
                }).catch((e) => {
                    console.log(e);
                })
            }
            else {
                setReturnFormResponse({ ...returnFormResponse, ResponseMessage: <T>Please select one or more product(s) to proceed.</T> })
                setTimeout(() => {
                    setReturnFormResponse({ ...returnFormResponse, ResponseMessage: "" })
                }, 5000);
            }
        }
    }


    // Modal states and methods
    const [isReturnModalOpen, setIsReturnModalOpen] = useState(false);
    const openReturnModal = () => {
        setIsReturnModalOpen(true);
    };
    const closeReturnModal = () => {
        setIsReturnModalOpen(false);
    };

    const [isReasonModalOpen, setIsReasonModalOpen] = useState(false);
    const openReasonModal = () => {
        setIsReasonModalOpen(true);
    };
    const closeReasonModal = () => {
        setIsReasonModalOpen(false);
    };
    const handleReasonClick = (ShippingId, ProductId, sectionToOpen) => {
        const updatedReturnableItems = returnableItems.map((shipment) => {
            if (shipment.ShipmentId === ShippingId) {
                return {
                    ...shipment,
                    ShipmentItems: shipment.ShipmentItems.map((p) => {
                        if (p.ProductId === ProductId) {
                            setReturnModalPictureUrl(p.PictureUrl)
                            setSelectedReasonId(p.ReasonId)
                            setProofPicture(p.BlobImage)
                            return {
                                ...p,
                            }
                        }
                        else {
                            return p;
                        }
                    })
                }
            }
            else {
                return shipment
            }
        })
        toggleReturnSections(sectionToOpen)
        openReasonModal();
        setReturnableIndexes({ ShippingId: ShippingId, ProductId: ProductId })

    }

    const [returnRequestSuccess, setReturnRequestSuccess] = useState(false);
    const openReturnSuccessModal = () => {
        setReturnRequestSuccess(true);
    };
    const closeReturnSuccessModal = () => {
        // setReturnRequestSuccess(false);
        window.location.reload(); 
    };

    return (
        <>
            <div className='profile-body-header mb-4 d-flex align-items-center justify-between flex-wrap'>
                <div>
                    <h1 className='fs-24 text-gray-500 fw-500 mt-0 mb-0'><T>Your Order</T></h1>
                    <p className='fs-16 text-gray-400 fw-400 mt-0 mb-2'><T>Below is a history of your purchased items.</T></p>
                </div>
                <div className="dropdown-btn-container" ref={dropdownRef}>
                    <button className="btn btn-primary order-detail-btn" onClick={toggleDropdown}><T>Order Options</T></button>
                    {isOpen && (
                        <ul className="dropdown-menu">
                            {isReturnable ? <> <li onClick={openReturnModal}><T>Return Items</T></li></> : null}
                            {removeZetca ? null : <><li onClick={sendZetcaInvoice}><T>Request Invoice</T></li></>}
                            {orderDetails.IsCancellable && orderDetails.PaymentStatus !== "Refunded" && orderReasons.length ?
                                <><li onClick={() => { setCancelPopup(true) }}><T>Cancel Order</T></li></>
                                : null
                            }

                        </ul>
                    )}
                </div>
                {/* <Modal isOpen={isModalOpen} closeModal={closeModal} /> */}
                <>
                    {isReturnModalOpen && (
                        <div className="modal-overlay">
                            <div className="modal return-items-modal">
                                <div className="modal-header">
                                    <h2 className="fs-20 text-gray-500 fw-500"><T>Select Return Items</T></h2>
                                    <a href="#" className="close-icon" onClick={closeReturnModal}>
                                        <i className="ri-close-line"></i>
                                    </a>
                                </div>
                                <div className="modal-content">
                                    {loading ? <span className="btn btn-alternative w-100 mb-4"><T>Please wait while your request is submitting.</T></span> :
                                        <>
                                            <div className="warning-alert danger-bg pt-3 pb-3 pl-3 pr-3 mt-2 mb-2">
                                                <p className="mt-1 mb-1 fs-16 fw-500 text-danger">
                                                    <i className="ri-error-warning-line text-danger mr-2 fs-22 fw-500"></i>
                                                    <T>Items should have all the accessories with the original box</T>
                                                </p>
                                            </div>
                                            <div className="modal-card">
                                                {returnableItems.map((shipment, index) => {
                                                    return (
                                                        <div key={shipment.ShipmentId} id="product-shipments">
                                                            <div className="order-details-container">
                                                                <div className="shipment-heading">
                                                                    <h1 className='fs-14 text-gray-500 fw-500 mt-0 mb-0'><i className="ri-box-3-line fs-18"></i> <T>Shipment</T> {`${shipment.ShipmentId}`}</h1>
                                                                </div>
                                                                {shipment.ShipmentItems.map((si, j) => {
                                                                    return (
                                                                        <div key={si.Id} className="modal-card-wrapper">
                                                                            <div className="d-flex align-items-center justify-start position-relative mb-3 mt-3 mx-4">
                                                                                <div className="product-checkbox">
                                                                                    <input className="input-checkbox" type="checkbox" id={si.Id} checked={si.IsSelected} onChange={(e) => updateReturnableProductIsSelected(e, shipment.ShipmentId, si.ProductId)} />
                                                                                    <label htmlFor={si.Id}></label>
                                                                                </div>
                                                                                <div className="product-description d-flex align-items-center justify-between flex-wrap">
                                                                                    <div className="card-image text-center">
                                                                                        <Link className="product-image">
                                                                                            <img src={si.PictureUrl ? si.PictureUrl : noImage} alt={si.Name} draggable={false} onError={(e) => { e.target.onerror = null; e.target.src = noImage }} />
                                                                                        </Link>
                                                                                    </div>
                                                                                    <div className="card-meta">
                                                                                        <div className="card-title-brand d-inline-flex flex-column" style={{ maxWidth: "250px" }}>
                                                                                            <Link className="product-title decoration-none text-gray-500 fs-16 fw-500" title={si.Name}>{si.Name}</Link>
                                                                                        </div>
                                                                                        <div className="product-quantity">
                                                                                            <div className="product-quantity-container d-inline-flex align-items-center">
                                                                                                <button className="product-quantity-btn" onClick={() => { si.returnQuantity <= 1 ? updateReturnableProductQuantity(1, shipment.ShipmentId, si.ProductId) : updateReturnableProductQuantity(si.returnQuantity - 1, shipment.ShipmentId, si.ProductId) }}>-</button>
                                                                                                <input className="product-quantity-field" value={si.returnQuantity} placeholder="1" readOnly />
                                                                                                <button className="product-quantity-btn" onClick={() => { si.returnQuantity < si.Quantity ? updateReturnableProductQuantity(si.returnQuantity + 1, shipment.ShipmentId, si.ProductId) : updateReturnableProductQuantity(si.returnQuantity, shipment.ShipmentId, si.ProductId) }}>+</button>
                                                                                            </div>
                                                                                        </div>
                                                                                        {
                                                                                            si.ProofImage && si.ProofImage.name && si.ReasonId && si.ReasonText ?
                                                                                                <div className="return-reason-image">
                                                                                                    <p className="return-image fs-14 fw-500 text-gray-400 mt-2 mb-1 cursor-pointer"><T>Image</T>: <span className="text-primary" onClick={() => handleReasonClick(shipment.ShipmentId, si.ProductId, false)}>{si.ProofImage.name}</span></p>
                                                                                                    <p className="return-reason fs-14 fw-500 text-gray-400 mt-1 mb-0 cursor-pointer"><T>Reason</T>: <span className="text-secondary" onClick={() => handleReasonClick(shipment.ShipmentId, si.ProductId, true)}>{si.ReasonText}</span></p>
                                                                                                </div> :
                                                                                                si.ReasonId && si.ReasonText ?
                                                                                                <p className="return-reason fs-14 fw-500 text-gray-400 mt-1 mb-0 cursor-pointer"><T>Reason</T>: <span className="text-secondary" onClick={() => handleReasonClick(shipment.ShipmentId, si.ProductId, true)}>{si.ReasonText}</span></p> :
                                                                                                <div className="card-btn">
                                                                                                    <button className={si.IsSelected ? "btn btn-primary" : "btn btn-disable"} disabled={si.IsSelected ? '' : "disabled"} onClick={() => handleReasonClick(shipment.ShipmentId, si.ProductId, true)}><T>Return Reason</T></button>
                                                                                                </div>
                                                                                        }
                                                                                        {/* {
                                                                                            si.ReasonId && si.ReasonText ?
                                                                                                <div className="return-reason-image">
                                                                                                    <p className="return-image fs-14 fw-500 text-gray-400 mt-2 mb-1 cursor-pointer">{translations.MyOrders[language].image}: <span className="text-primary" onClick={() => handleReasonClick(shipment.ShipmentId, si.ProductId, false)}>{si.ProofImage.name}</span></p>
                                                                                                    <p className="return-reason fs-14 fw-500 text-gray-400 mt-1 mb-0 cursor-pointer">{translations.MyOrders[language].reason}: <span className="text-secondary" onClick={() => handleReasonClick(shipment.ShipmentId, si.ProductId, true)}>{si.ReasonText}</span></p>
                                                                                                </div> :
                                                                                                <div className="card-btn">
                                                                                                    <button className={si.IsSelected ? "btn btn-primary" : "btn btn-disable"} disabled={si.IsSelected ? '' : "disabled"} onClick={() => handleReasonClick(shipment.ShipmentId, si.ProductId, true)}>{translations.MyOrders[language].returnReasonbtn}</button>
                                                                                                </div>
                                                                                        } */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                returnFormErrors[index][j].ReasonError.length || returnFormErrors[index][j].QuantityError.length ?
                                                                                    <ul className="error-list">
                                                                                        {/* {
                                                                                            returnFormErrors[index][j].ImageError.length ?
                                                                                                <li>{returnFormErrors[index][j].ImageError}</li> : null
                                                                                        } */}
                                                                                        {
                                                                                            returnFormErrors[index][j].ReasonError.length ?
                                                                                                <li>{returnFormErrors[index][j].ReasonError}</li> : null
                                                                                        }
                                                                                        {
                                                                                            returnFormErrors[index][j].QuantityError.length ?
                                                                                                <li>{returnFormErrors[index][j].QuantityError}</li> : null
                                                                                        }
                                                                                    </ul> : null
                                                                            }
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    )
                                                })}

                                            </div>
                                            <div className="submit-return-container">
                                                {
                                                    // Object.entries(returnFormErrors).map(([errorType, errorMessage]) => {
                                                    //     let errorMessageDisplay = errorMessage != "" && <p key={errorType} className="mt-1 mb-1 fs-16 fw-500 text-danger text-center">
                                                    //         {'Error'}: {errorMessage}
                                                    //     </p>
                                                    //     return errorMessageDisplay

                                                    // })
                                                    returnFormResponse.ResponseMessage != "" && <>
                                                        <p className="mt-1 mb-1 fs-16 fw-500 text-danger text-center">
                                                            {returnFormResponse.ResponseMessage}
                                                        </p>
                                                    </>
                                                }
                                                <p className="mt-1 mb-1 fs-12 fw-400 text-center"><T>Click here to read our</T> {<Link to={'/return-policy'} target="_blank" className="text-primary"><T>Return Policy</T></Link>} <T>and</T> <T>How to</T> {<Link to={'/return-item-policy'} target="_blank" className="text-primary"><T>Return Items</T></Link>}</p>
                                                <button className="btn btn-primary w-100" onClick={submitReturnRequest}><T>Request Return</T></button>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    )}
                </>

                <>
                    {isReasonModalOpen && (
                        <div className="modal-overlay">
                            <div className="modal reason-to-return-modal">
                                <div className="modal-header" style={{ marginBottom: "30px" }}>
                                    <a href="#" className="close-icon" onClick={closeReasonModal}>
                                        {/* <i className="ri-close-line"></i> */}
                                    </a>
                                </div>
                                <div className="modal-content">
                                    {reasonReturnSection ? (
                                        <>
                                        <div className="choose-reason-widget">
                                            <h2 className="fs-20 text-gray-500 fw-500 text-center"><T>Reason to return</T></h2>
                                            <div className="product-image text-center">
                                                <img src={returnModalPictureUrl} alt="Product Image" draggable={false} onError={(e) => { e.target.onerror = null; e.target.src = noImage }} />
                                            </div>
                                            <ul className="popup-reasons">
                                                {
                                                    // orderReasons.map((r, index) => {
                                                    //     return <li key={index} className="single-reason">
                                                    //         <input onChange={(e) => { if (e.target.checked) { setSelectedReason(e.target.value) } }} id={"reason" + index} name="cancelReason" type={"radio"} className="d-none" value={r.Id} />
                                                    //         <label htmlFor={"reason" + index}>
                                                    //             {r.Reason}
                                                    //         </label>
                                                    //     </li>
                                                    // })
                                                    orderReasons.map((r, index) => {
                                                        return <li key={index} className="single-reason">
                                                            <input checked={selectedReasonId === r.Id} onChange={() => updateReturnableProductReason(r.Id, r.Reason, returnableIndexes.ShippingId, returnableIndexes.ProductId)} id={"reason" + index} name="returnReason" type={"radio"} className="d-none" value={r.Id} />
                                                            <label htmlFor={"reason" + index}>
                                                                {r.Reason}
                                                            </label>
                                                        </li>
                                                    })
                                                }
                                                {/* <li className="single-reason">
                                                    <input id={"reason1"} type={"radio"} className="d-none" name="returnReason" onChange={() => updateReturnableProductReason(1, "Not Interested", returnableIndexes.ShippingId, returnableIndexes.ProductId)} />
                                                    <label htmlFor={"reason1"}>
                                                        Not Interested
                                                    </label>
                                                </li>
                                                <li className="single-reason">
                                                    <input id={"reason2"} type={"radio"} className="d-none" name="returnReason" onChange={() => updateReturnableProductReason(2, "Item Broken", returnableIndexes.ShippingId, returnableIndexes.ProductId)} />
                                                    <label htmlFor={"reason2"}>
                                                        Item Broken
                                                    </label>
                                                </li> */}
                                            </ul>
                                            
                                        </div>
                                        <button className="btn btn-primary w-100" onClick={() => toggleReturnSections(false)}><T>Continue</T></button>
                                        </>
                                    ) : (
                                        <div className="choose-image-widget">
                                            <h2 className="fs-20 text-gray-500 fw-500 text-center"><T>Upload an Image</T></h2>
                                            <div className="upload-proof-image text-center">
                                                <img src={proofPicture} alt="Upload Proof Image" />
                                                <p className="mt-1 mb-1 fs-16 fw-400 text-danger">
                                                    {pictureErrors.proofImage != "" ? pictureErrors.proofImage : ""}
                                                </p>
                                            </div>
                                            <label htmlFor="proof-image">
                                                <a className="btn btn-primary w-100 mb-2"><T>Browse</T></a>
                                                <input type="file" className="d-none w-100" name="" id="proof-image" onChange={(e) => updateProofPicture(e, returnableIndexes.ShippingId, returnableIndexes.ProductId)} />
                                            </label>
                                            {/* <button className="btn btn-secondary w-100 mb-2" onClick={() => toggleReturnSections(true)}>{translations.MyOrders[language].back}</button> */}
                                            <button className="btn btn-outline-secondary w-100" onClick={() => { setIsReasonModalOpen(false) }}><T>Finish</T></button>

                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                    )}
                </>

                <>
                    {returnRequestSuccess && (
                        <div className="modal-overlay">
                            <div className="modal return-request-success-modal">
                                <div className="modal-content">
                                    <h2 className="fs-20 text-gray-500 fw-500 text-center"><T>Return Request Initiated</T></h2>
                                    <p className="mt-1 mb-1 fs-16 fw-400 text-gray-500 text-center">
                                    <T>Your request to return items has been received. We will review your request and inform you within one working day.</T>
                                        {/* Your Return Request <span className="text-primary fw-500">#</span><span className="text-primary fw-500">{returnFormResponse.TrackingId}  {iscopied ? <i className="ri-checkbox-circle-fill text-primary"></i> : <i style={{ cursor: "pointer" }} onClick={() => handleCopyClick(returnFormResponse.TrackingId)} className="ri-file-copy-line text-primary"></i>}</span> */}
                                    </p>
                                    <div className="return-request-image text-center">
                                        <img src={returnRequestImage} alt="" className="mt-2 mb-2" />
                                    </div>
                                    <div className="action-btn-container">
                                        <Link to={`/account/returns`} className="btn btn-primary w-100 mb-2"><T>View Return Order</T></Link>
                                        <button className="btn btn-outline-primary w-100 mb-2" onClick={closeReturnSuccessModal}><T>Close</T></button>
                                        {/* <Link to={`/`} className="btn btn-outline-secondary w-100">{translations.MyOrders[language].backToHome}</Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>

            </div>
            {removeZetca ? <span className="btn btn-alternative w-100 mb-4"><i className="ri-checkbox-circle-line fs-22 mr-2"></i><T>Your order invoice has been sent to your registered email address.</T></span> : null}
            {
                loading ? <span className="btn btn-alternative w-100 mb-4"><T>Fetching Order data. Please wait....</T></span> :
                    orderDetails === null ? <span className="btn btn-alternative w-100 mb-4"><i className="ri-error-warning-line fs-22 mr-2"></i><T>We could not find the order you were looking for.</T></span> :
                        <>
                            <div className="order-details-container w-100">
                                <div className="order-details-header d-flex justify-between flex-wrap">
                                    <div className="order-no d-flex">
                                        <span className="fs-14 text-gray-400 fw-400"><T>Order</T> # </span>
                                        <span className="product-action text-secondary fw-500 fs-14 ml-2"> {orderDetails.OrderNo}</span>
                                    </div>
                                    <div className="order-items d-flex">
                                        <span className="fs-14 text-gray-400 fw-400"><T>Order Items</T> :</span>
                                        <span className="product-action text-gray-500 fw-600 fs-14 ml-2 text-capitalize"> {orderDetails.productOrderDTO.length}</span>
                                    </div>
                                    <div className="order-date d-flex">
                                        <span className="fs-14 text-gray-400 fw-400"><T>Placed On</T> :</span>
                                        <span className="product-action text-gray-500 fw-600 fs-14 ml-2 LTR"> {orderDetails.OrderDate}</span>
                                    </div>
                                    <div className="order-status d-flex">
                                        <span className="fs-14 text-gray-400 fw-400"><T>Status</T> :</span>
                                        <span className="product-action text-gray-500 fw-600 fs-14 ml-2 text-capitalize"> {statuses[orderDetails.Status] ? statuses[orderDetails.Status][language] : orderDetails.Status}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="order-details-container w-100">
                                {
                                    orderDetails.OccasionDetails ? <span className="occasion-order-tag">{"Occasion: " + orderDetails.OccasionDetails.OccasionTitle}</span> : null
                                }
                                <div className="order-tracking-heading text-center">
                                    {
                                        orderDetails.IsCancelled || orderDetails.Status === "ReturnToOrigin" || orderDetails.PaymentStatus === "Refunded" ?
                                            <p className="mb-0 mt-2 fs-14 fw-500 text-gray-500"><T>Cancelled</T>: <span className="LTR d-inline-block">{orderDetails.CancellationDate}</span></p> :
                                            orderDetails.IsDelivered ?
                                                <p className="mb-0 mt-2 fs-14 fw-500 text-gray-500"><T>Delivered</T>: <span className="LTR d-inline-block">{orderDetails.CompletionDate}</span></p> :
                                                orderDetails.ExpectedDelivery ?
                                                    <p className="mb-0 mt-2 fs-14 fw-500 text-gray-500"><T>Estimated</T>: <span className="LTR d-inline-block">{orderDetails.ExpectedDelivery}</span></p> : null
                                    }
                                    <p className={`fs-16 text-gray-500 ${orderDetails.IsCancelled ? "mt-2 mb-3" : "mt-2"}`}><T>Your Order</T> #<span className="fw-500 text-primary">{orderDetails.OrderNo}</span> <T>is</T> <span className="fw-500 text-gray-500">{statuses[orderDetails.Status] ? statuses[orderDetails.Status][language] : orderDetails.Status}</span></p>
                                    {orderDetails.IsCancelled && orderDetails.OrderFailureReason ? <><h4 className="fs-14 fw-500 text-primary mb-0"><T>Cancel Reason</T></h4><p className="fs-14 fw-400 text-gray-500 m-0">{orderDetails.OrderFailureReason}</p></> : null}
                                </div>
                                <div className="order-return-date">
                                    {
                                        orderDetails.Status === "Delivered" && orderDetails.productOrderDTO.filter((a, i) => a.IsReturntable).length ?
                                            <div className="text-center mb-5">
                                                <span className="fs-14 text-gray-400 fw-400"><T>Returnable by</T>:</span>
                                                <span className="product-action text-gray-500 fw-600 fs-14 mx-2 text-capitalize LTR d-inline-block">{orderDetails.NoOfDaysRemaining}</span>
                                            </div> : null
                                    }
                                </div>
                            </div>

                            <div className='order-list-header mb-4 d-flex align-items-center justify-between flex-wrap'>
                                <div>
                                    <h2 className='fs-20 text-gray-500 fw-500 mt-0 mb-0'><T>Order Items</T></h2>
                                    <p className='fs-16 text-gray-400 fw-400 mt-0 mb-2'><T>Below is a history of your order items.</T></p>
                                </div>
                                {orderDetails.ShipmentAvailability ?
                                    <div>
                                        <Link to={`/account/orders/shipments/${orderID}`} className="btn btn-primary order-detail-btn"><T>View Shipment</T></Link>
                                    </div> : null
                                }

                            </div>
                            <div className="order-products-wrapper">
                                {
                                    orderDetails.productOrderDTO.map((i, key) => (
                                        <OrderProductDetails update={update} setUpdate={setUpdate} item={i} key={key} language={language} status={orderDetails.Status} returnReason={orderReasons} orderId={orderID} />
                                    ))
                                }
                            </div>
                            <div className="d-flex justify-between flex-wrap w-100">
                                <div className="shipping-total-bill-container">
                                    <div className="shipping-billing d-flex">
                                        <h2 className="fs-22 fw-500 text-gray-500 mt-2 mb-4 border-line d-flex"><T>Shipping & Billing Address</T></h2>
                                    </div>
                                    {
                                        shipping ?
                                            <div className="shipping-address d-flex justify-between mb-4">
                                                <div className="shipping-detail">
                                                    <p className="fs-14 fw-500 text-secondary mt-0 mb-1"><T>Shipping Address</T></p>
                                                    {
                                                        (shipping.HouseNo && shipping.HouseNo.length) || (shipping.StreetAddress && shipping.StreetAddress.length) || (shipping.CityName && shipping.CityName.length) || (shipping.RegionName && shipping.RegionName.length) || (shipping.ZipCode && shipping.ZipCode.length) ?
                                                        <span className="fs-14 fw-500 text-gray-500">{shipping.HouseNo && shipping.HouseNo.length ? shipping.HouseNo + ", " : ""}{shipping.StreetAddress && shipping.StreetAddress.length ? shipping.StreetAddress + ", " : ""}{shipping.CityName && shipping.CityName.length ? shipping.CityName + ", " : ""}{shipping.RegionName && shipping.RegionName ? shipping.RegionName + ", " : ""}{shipping.ZipCode && shipping.ZipCode.length ? shipping.ZipCode : ""}</span> :
                                                        <span className="fs-14 fw-500 text-gray-500"><T>We will contact the recipient for the address</T></span>
                                                    }
                                                </div>
                                                {
                                                    shipping.Title && shipping.Title.length ?
                                                        <div className="tag">
                                                            <p className="bg-link text-light mt-0 fs-12">{addressTitles[shipping.Title.toLowerCase()] ? addressTitles[shipping.Title.toLowerCase()][language] : shipping.Title}</p>
                                                        </div> : null
                                                }
                                            </div> : null
                                    }
                                    {
                                        billing && shipping && ((billing.HouseNo && billing.HouseNo.length) || (billing.StreetAddress && billing.StreetAddress.length) || (billing.CityName && billing.CityName.length) || (billing.RegionName && billing.RegionName.length) || (billing.ZipCode && billing.ZipCode.length)) ?
                                            <div className="billing-address d-flex justify-between">
                                                <div className="billing-detail">
                                                    <p className="fs-14 fw-500 text-secondary mt-0 mb-1"><T>Billing Address</T></p>
                                                    <span className="fs-14 fw-500 text-gray-500">{billing.HouseNo && billing.HouseNo.length ? billing.HouseNo + ", " : ""}{billing.StreetAddress && billing.StreetAddress.length ? billing.StreetAddress + ", " : ""}{billing.CityName && billing.CityName.length ? billing.CityName + ", " : ""}{billing.RegionName && billing.RegionName ? billing.RegionName + ", " : ""}{billing.ZipCode && billing.ZipCode.length ? billing.ZipCode : ""}</span>
                                                </div>
                                                {
                                                    billing.Title && billing.Title.length ?
                                                        <div className="tag">
                                                            <p className="bg-link text-light mt-0 fs-12">{addressTitles[billing.Title.toLowerCase()] ? addressTitles[billing.Title.toLowerCase()][language] : billing.Title}</p>
                                                        </div> : null
                                                }
                                            </div> : null
                                    }
                                </div>
                                <div className="shipping-total-bill-container">
                                    <div className="total-summary d-flex">
                                        <h2 className="fs-22 fw-500 text-gray-500 mt-2 mb-4 border-line d-flex"><T>Total Summary</T></h2>
                                    </div>
                                    <div className="subtotal d-flex justify-between">
                                        <div className="subtotal-heading">
                                            <p className="fs-14 fw-400 text-gray-500 mb-1"><T>Sub Total</T><span className="fs-12 text-gray-300 d-block"><T>Inclusive of VAT</T></span></p>
                                        </div>
                                        <div className="subtotal-value">
                                            <p className="fs-14 fw-500 text-gray-500">SAR {orderDetails.SubTotal.toFixed(2)}</p>
                                        </div>
                                    </div>
                                    {
                                        orderDetails.OrderTaxTotal ?
                                            <div className="discount d-flex justify-between">
                                                <div className="discount-heading">
                                                    <p className="fs-14 fw-400 text-gray-500"><T>Tax (VAT)</T></p>
                                                </div>
                                                <div className="discount-value">
                                                    <p className="fs-14 fw-500 text-gray-500">SAR {orderDetails.OrderTaxTotal.toFixed(2)}</p>
                                                </div>
                                            </div> : null
                                    }
                                    <div className="total d-flex justify-between">
                                        <div className="shippingfee-heading">
                                            <p className="fs-14 fw-400 text-gray-500"><T>Shipping</T></p>
                                        </div>
                                        <div className="shippingfee-value">
                                            <p className="fs-14 fw-500 text-gray-500">{orderDetails.ShippingTotal ? "+ SAR " + orderDetails.ShippingTotal.toFixed(2) : <T>Free</T>}</p>
                                        </div>
                                    </div>
                                    {
                                        orderDetails.TotalDiscountAmount ?
                                            <div className="discount d-flex justify-between">
                                                <div className="discount-heading">
                                                    <p className="fs-14 fw-400 text-gray-500"><T>Discount</T></p>
                                                </div>
                                                <div className="discount-value">
                                                    <p className="fs-14 fw-500 text-gray-500" style={{direction: "ltr"}}> SAR - {orderDetails.TotalDiscountAmount.toFixed(2)}</p>
                                                </div>
                                            </div> : null
                                    }
                                    <div className="total d-flex justify-between" style={{ borderTop: "1px dashed var(--gray-100)" }}>
                                        <div className="total-heading">
                                            <p className="fs-16 fw-500 text-gray-500"><T>Order Total</T></p>
                                        </div>
                                        <div className="total-value ">
                                            <p className="fs-16 fw-500 text-secondary">SAR {orderDetails.TotalAmount.toFixed(2)}</p>
                                        </div>
                                    </div>
                                    {
                                        orderDetails.AppliedPromoCodes.filter((c) => c ? true : false).length ?
                                            <div className="coupon-codes-container d-flex align-items-center justify-between" style={{ borderTop: "1px dashed var(--gray-100)" }}>
                                                <div className="total-heading mr-1">
                                                    <p className="fs-16 fw-500 text-gray-500"><T>Coupons</T></p>
                                                </div>
                                                <div className="coupon-codes text-right">
                                                    { orderDetails.AppliedPromoCodes.map((code, key) => { return code ? <span key={key}>{code}</span> : null }) }
                                                </div>
                                            </div> : null
                                    }
                                </div>
                            </div>
                            <div className="payment-info order-details-container w-100">
                                <div className="shipping-billing d-flex">
                                    <h2 className="fs-22 fw-500 text-gray-500 mt-2 mb-4 border-line d-flex"><T>Payment Information</T></h2>
                                </div>
                                {
                                    orderDetails.PaymentMethod === "Card Payment" && orderDetails.CardDetails ?
                                        <div className="mb-3">
                                            <p className="mt-0 text-gray-500 fw-400 fs-14"><T>The order was placed using</T>&nbsp;<b className="fw-500 text-secondary"><T>Debit/Credit Card</T></b></p>
                                            <div className="card-details d-flex align-items-center">
                                                <img width={50} src={orderDetails.CardDetails.Scheme ? cardIcons[orderDetails.CardDetails.Scheme.toLowerCase().replaceAll(" ", "-")] : cardIcons["undefined"]} alt="" />
                                                <div className="ml-2">
                                                    <h5 className="text-uppercase fw-500 fs-16 text-gray-500 m-0">{orderDetails.CardDetails.Scheme} <span>**** {orderDetails.CardDetails.Last4}</span></h5>
                                                </div>
                                            </div>
                                        </div> :
                                        orderDetails.PaymentMethod === "Tamara" ?
                                        <div className="mb-3">
                                            <div style={{ fontFamily: "'Helvetica Neue', sans-serif",fontSize: '14px', textAlign: 'left', padding: '16px', direction: language === "ar" ? "rtl" : "ltr", lineHeight: '22px', color: 'rgb(0, 0, 0)', minWidth: '100%', boxSizing: 'border-box', position: 'relative', borderRadius: '10px', borderColor: 'rgb(229, 229, 229)', borderStyle: 'solid', borderWidth: '1px', display: "flex", alignItems: "center", justifyContent: "space-between" }} dir={ language === "ar" ? "rtl" : "ltr"}>
                                                <p className="m-0"><T>The order was placed using</T>&nbsp;<b className="fw-500 text-secondary"><T>Tamara</T></b></p>
                                                <img style={{ height: '28px', display: 'inline-block', width: "auto" }} alt="Tamara" className="tamara-logo" src={ language === "ar" ? "https://cdn.tamara.co/widget-v2/assets/tamara-grad-ar.a20a9a81.svg" : "https://cdn.tamara.co/widget-v2/assets/tamara-grad-en.ac5bf912.svg"}/>
                                            </div>
                                        </div> :
                                        <div className="mb-3">
                                            <p className="mt-0 text-gray-500 fw-400 fs-14"><T>The order was placed using</T>&nbsp;<b className="fw-500 text-secondary"><T>Cash on Delivery</T></b></p>
                                        </div>
                                }
                            </div>
                            <div className="d-flex align-items-center justify-end">
                                {
                                    orderDetails.IsCancellable && orderReasons.length ?
                                    cancelPopup ?
                                    <section className="order-popup">
                                        <div className="order-popup-overlay" onClick={() => { setCancelPopup(false) }}></div>
                                        <div className="order-popup-wrapper">
                                            <span className="popup-close" onClick={() => { setCancelPopup(false) }}><i className="ri-close-line"></i></span>
                                            <h4 className="popup-heading"><T>Reason for Order Cancel</T></h4>
                                            <ul className="popup-reasons">
                                                {
                                                    orderReasons.map((r, index) => {
                                                        return <li key={index} className="single-reason">
                                                            <input onChange={(e) => { if (e.target.checked) { setSelectedReason(e.target.value) } }} id={"reason" + index} name="cancelReason" type={"radio"} className="d-none" value={r.Id} />
                                                            <label htmlFor={"reason" + index}>
                                                                {r.Reason}
                                                            </label>
                                                        </li>
                                                    })
                                                }
                                            </ul>
                                            {errors.reason ? <span className="text-primary fs-14 fw-400 w-100 text-center d-inline-block mb-4">{errors.reason}</span> : null}
                                            {
                                                update === 0 ?
                                                    <div className="d-flex align-items-center popup-footer">
                                                        <button onClick={() => { setCancelPopup(false) }} type="button" className="btn btn-outline-secondary"><T>Close</T></button>
                                                        <button onClick={validateSubmit} type="button" disabled={apiLoading} className={!apiLoading ? "btn btn-primary ml-2" : "btn btn-disabled ml-2"}><T>Confirm</T></button>
                                                    </div> : null
                                            }
                                        </div>
                                    </section> 
                                    : null : null
                                }
                            </div>
                        </>
            }
        </>
    )
}

export default ViewOrderDetails;